import axios from "axios";

function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export async function getMeApi() {
  let myHeaders = new Headers();
  const auth_name = "auth_token";
  let authtoken = getCookie(auth_name);
  authtoken = "Token " + authtoken;
  myHeaders.append("Authorization", authtoken);
  const config = {
    headers: { Authorization: authtoken },
  };

  try {
    const resp = await axios.get(
      "https://preprod-api.tbplabs.tech/user/me",
      config
    );
    return resp;
  } catch (err) {
    return "error";
  }
}
