import React, {useCallback, useEffect, useMemo, useRef, useState,} from "react";
import ReactFlow, {addEdge, Background, Controls, MiniMap, useEdgesState, useNodesState,} from "reactflow";
import "reactflow/dist/style.css";
import {TextNode, TextNodeEditModal} from "./nodes/msgNode";
import {DelayNode, DelayNodeEditModal} from "./nodes/delay";
import {ConditionalNode, ConditionalNodeContent, initialCondition} from "./nodes/conditional";
import {generateId, initialNodes, NodeTypes} from "./constants";
import axios from "axios";
import Cookies from "js-cookie";
import {useParams} from "react-router-dom/cjs/react-router-dom.min";
import {SendMessageEditModal, SendMessageNode} from "./nodes/sendMessage";
import withFunctions from "./nodes/willFunctions";
import {PopperModal} from "./popperModal";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {ImageNode, ImageNodeModal} from "./nodes/image";
import {ListEditModal, ListNode} from "./nodes/list";
import {QuickReplyEditModal, QuickReplyNode} from "./nodes/quickReply";
import {DocumentNode, DocumentNodeModal} from "./nodes/document";
import {useHistory} from "react-router-dom";
const WorkflowV2 = () => {
    const [editName, setEditName] = useState(false);
    const [appAccountId, setAppAccountId] = useState(null)
    const [appOptions, setAppOptions] = useState([])
    const [anchorEl, setAnchorEl] = useState(null);
    const [render, setRender] = useState(0)
    const [workFlowName, setWorkFlowName] = useState('')
    const [templateMap, setTemplateMap] = useState({})
    const [isPrevNodeCondition, setPrevNodeCondition] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (!appAccountId) {
            setTimeout(()=> {
                alert('Please select App first')

            },1000)
        }
    }, []);

    const [popperOpen, setOpenPopper] = React.useState({
        isOpen: false,
        prevId: null,
    });
    const [open1, setOpen1] = React.useState({isOpen: false, id: null, data: []});
    const [textNodeEditModal, setTextNodeEditModal] = useState({
        id: null,
        open: false,
    });
    const [delayNodeEditModal, setDelayNodeEditModal] = useState({
        id: null,
        open: false,
    });
    const [mediaNodeEditModal, setMediaNodeEditModal] = useState({
        id: null,
        type: null,
    });

    const [sendMessageEditModal, setSendMessageEditModal] = useState({
        id: null,
        open: false,
    });
    const reactFlowWrapper = useRef(null);
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [reactFlowInstance, setReactFlowInstance] = useState(null);
    const [selectedElements, setSelectedElements] = useState([]);
    const [nodeName, setNodeName] = useState("");
    const [triggerData, setTriggerData] = useState([])
    const {id} = useParams();

    console.log('nodesnodes', nodes, edges)


    useEffect(async () => {
        const authToken = `Token ${Cookies.get("auth_token")}`;

        const config = {
            headers: {Authorization: authToken},
        };

        try {
            const currentUserListData = await axios.get(
                "https://preprod-api.tbplabs.tech/chatapp/getChatApps",
                config
            );
            setAppOptions((currentUserListData.data || []));
        } catch (err) {
            console.error("Error fetching app account ID:", err);
        }
    }, [])

    const isAddVisible = (data) => {
        return nodes[nodes.length - 1]?.id === data.id;
    };
    const onAdd = (e, rest, nodeId = null, extraData) => {
        setAnchorEl(e?.currentTarget);
        setOpenPopper({isOpen: true, prevId: nodeId, nodeId, extraData});
        if (extraData?.type === NodeTypes.Conditional) {
            setPrevNodeCondition(true)
        } else {
            setPrevNodeCondition(false)
        }
        // setPrevNode(nodes.find(i=> i))
    };

    const auth = {
        headers: {Authorization: `Token ${Cookies.get("auth_token")}`},
    };

    async function submitFlow() {
        const API = "https://preprod-api.tbplabs.tech/flow/v2/flow";
        const now = new Date();
        const dateTimeString = now.toLocaleString();
        const data = {

            name: "Test_" + dateTimeString,
            trigger: triggerData,
            data: {
                nodes: nodes,
                edges: edges,
            },
        };
        if (id) {
            data.id = Number(id)
            try {
                const resp = await axios.put(API, data, auth)
                if (resp) {
                    alert('Updated successfully')
                    history.push('/Workflow')
                }
            } catch (e) {

            }
        } else {
            try {
                const resp = await axios.post(API, data, auth)
                if (resp) {
                    alert('Saved successfully')
                    history.push('/Workflow')
                }
            } catch (e) {

            }
        }

    }

    useEffect(async () => {
        if (id) {
            const API = "https://preprod-api.tbplabs.tech/flow/v2/flow?id=" + id;
            try {
                const currentWorkFlow = await axios.get(API, auth);
                if (currentWorkFlow) {
                    const nodes = currentWorkFlow.data[0].data.nodes || []
                    const edges = currentWorkFlow.data[0].data.edges || []
                    const _trigger = currentWorkFlow.data[0].trigger || []
                    setNodes(nodes)
                    setEdges(edges)
                    setTriggerData(_trigger)
                    setWorkFlowName(currentWorkFlow.data[0]?.name || "")
                    setAppAccountId(currentWorkFlow.data[0]?.appAccountId)
                    getTemplates(currentWorkFlow.data[0]?.appAccountId)
                }

            } catch (e) {

            }
        }
    }, [id]);

    const onEdit = (data) => {
        if (data.type === NodeTypes.Text) {
            setTextNodeEditModal({
                prevId: null,
                id: data.id,
                open: true,
                data: data,
            });
        } else if (data.type === NodeTypes.Delay) {
            setDelayNodeEditModal({...delayNodeEditModal, id: data.id, open: true, data: data})
        } else if (data.type === NodeTypes.SendMessage) {
            setSendMessageEditModal({...sendMessageEditModal, id: data.id, open: true, data: data})
        } else {
            setMediaNodeEditModal({...mediaNodeEditModal, id: data.id, type: data.type, data: data})
        }
    }

    //print the node here

    const addCondition = (id, data) => {
        setOpen1({isOpen: true, id: id, data:data });
    };


    // Handle node click
    const onNodeClick = useCallback((event, node) => {
        setSelectedElements([node]);
        setNodeName(node.data.label);
        setNodes((nodes) =>
            nodes.map((n) => ({
                ...n,
                selected: n.id === node.id,
            }))
        );
    }, []);

    const onMessageNodeAdd = () => {

        const newGeneratedId = generateId();
        setTextNodeEditModal({
            prevId: popperOpen.prevId,
            id: newGeneratedId,
            open: true,
            extraData: popperOpen.extraData
        });
        setOpenPopper({isOpen: false, prevId: null});
    };
    const onNodeAdded = (type) => {
        const newGeneratedId = generateId();
        setMediaNodeEditModal({
            prevId: popperOpen.prevId,
            id: newGeneratedId,
            type: type,
            extraData: popperOpen.extraData
        });
        setOpenPopper({isOpen: false, prevId: null});
    };

    const rfStyle = {
        backgroundColor: "#ffffff",
    };

    const onMessageUpdate = (text, isEdit) => {
        if (isEdit) {
            let nodeIndex = (nodes || []).findIndex(i => i.id === textNodeEditModal.id)
            if (nodeIndex > -1) {
                let _nodes = [...nodes]
                _nodes[nodeIndex].data.label = text;
                setNodes([..._nodes])
                setRender(v => v + 1)
            } else {
                console.log('node not found')
            }
        } else {
            if (text) {
                let positionObj = {};
                const newGeneratedId = generateId();
                const lastNode = nodes[nodes.length - 1]
                let lastPosition = lastNode.position || {
                    x: 100,
                    y: 100,
                };
                positionObj = {x: lastPosition.x + 200, y: lastPosition.y};
                const newNode = {
                    id: newGeneratedId,
                    type: NodeTypes.Text,
                    position: positionObj,
                    data: {label: text},
                };

                const edgeLen = edges.length;
                const newEdge = {
                    source:
                        textNodeEditModal.prevId ||
                        edges[edgeLen - 1]?.target ||
                        "1",
                    target: newGeneratedId,
                };
                if (textNodeEditModal.extraData?.key) {
                    newEdge.sourceHandle = textNodeEditModal.extraData.key
                }
                if (lastNode.type === NodeTypes.Conditional) {
                    // set addNew false for that condition
                    let conditionIndex = (lastNode.data?.conditions || [])
                        .findIndex(i => i.nodeId === textNodeEditModal?.extraData?.conditionId)
                    if (conditionIndex > -1) {
                        lastNode.data.conditions[conditionIndex] = {
                            ...lastNode.data.conditions[conditionIndex],
                            showAdd: false
                        }
                    }
                    let _nodes = [...nodes]
                    _nodes[nodes.length - 1] = lastNode
                    setNodes(_nodes.concat(newNode));

                } else {
                    setNodes((nds) => nds.concat(newNode));
                }
                if (!isPrevNodeCondition) {
                    // setEdges((eds) => addEdge(newEdge, eds));
                }
            }
        }

        setTextNodeEditModal({id: null, open: false, prevId: null});
    };
    const onConditionalNodeAdd = () => {
        const newGeneratedId = generateId();
        let positionObj = {};
        let lastPosition = nodes[nodes.length - 1]?.position || {
            x: 100,
            y: 100,
        };
        positionObj = {x: lastPosition.x + 200, y: lastPosition.y};
        const node = {
            id: newGeneratedId,
            type: NodeTypes.Conditional,
            content: "",
            data: {
                label: "",
                conditions: initialCondition,
                elseId: newGeneratedId + "else",
                showElseAdd: true,
            },
            position: positionObj,
        };
        const edgeLen = edges.length;
        const newEdge = {
            source: popperOpen.prevId || edges[edgeLen - 1]?.target || "1",
            target: newGeneratedId,
        };
        // setEdges((eds) => addEdge(newEdge, eds));

        setNodes((nds) => nds.concat(node));
        setOpenPopper({isOpen: false, prevId: null});
    };

    const onDelayNodeAdd = ({min, hour, days}, isEdit) => {
        if (isEdit) {
            let nodeIndex = (nodes || []).findIndex(i => i.id === delayNodeEditModal.id)
            if (nodeIndex > -1) {
                let _nodes = [...nodes]
                _nodes[nodeIndex].data = {
                    min: min,
                    hour: hour,
                    days: days,
                };
                setNodes([..._nodes])
                setRender(v => v + 1)
                setDelayNodeEditModal({id: null, open: false, prevId: null});
            } else {
                console.log('node not found')
            }
        } else {
            if (min === -1 && hour === -1 && days === -1) {
                setDelayNodeEditModal({id: null, open: false, prevId: null});
                return;
            }
            if (min || hour || days) {
                let positionObj = {};
                const newGeneratedId = generateId();
                let lastPosition = nodes[nodes.length - 1].position || {
                    x: 200,
                    y: 200,
                };
                positionObj = {x: lastPosition.x + 200, y: lastPosition.y};
                const newNode = {
                    id: newGeneratedId,
                    type: NodeTypes.Delay,
                    position: positionObj,
                    data: {
                        min: min,
                        hour: hour,
                        days: days,
                    },
                };

                const edgeLen = edges.length;
                const newEdge = {
                    source:
                        DelayNodeEditModal.prevId ||
                        edges[edgeLen - 1]?.target ||
                        "1",
                    target: newGeneratedId,
                    label: 'X'
                };
                if (!isPrevNodeCondition) {
                    // setEdges((eds) => addEdge(newEdge, eds));
                }
                setNodes((nds) => nds.concat(newNode));

                setDelayNodeEditModal({id: null, open: false, prevId: null});
                setOpenPopper({
                    isOpen: false,
                });
            } else {
                setDelayNodeEditModal({id: null, open: true, prevId: null});
            }
        }


        //
    };

    const onMediaNodeAdd = (type, data, isEdit) => {
        if (isEdit) {
            if(data){
                let nodeIndex = (nodes || []).findIndex(i => i.id === mediaNodeEditModal.id)
                if (nodeIndex > -1) {
                    let _nodes = [...nodes]
                    _nodes[nodeIndex].data = data;
                    setNodes([..._nodes])
                    setRender(v => v + 1)
                    setDelayNodeEditModal({id: null, open: false, prevId: null});
                } else {
                    console.log('node not found')
                }
            }
        } else if (data) {
            let positionObj = {};
            const newGeneratedId = generateId();
            let lastPosition = nodes[nodes.length - 1].position || {
                x: 100,
                y: 100,
            };
            positionObj = {x: lastPosition.x + 200, y: lastPosition.y};
            const newNode = {
                id: newGeneratedId,
                type: type,
                position: positionObj,
                data: data,
            }
            const edgeLen = edges.length;
            const newEdge = {
                source: mediaNodeEditModal?.prevId || edges[edgeLen - 1]?.target || "1",
                target: newGeneratedId,
            };
            if (textNodeEditModal.extraData?.key) {
                newEdge.sourceHandle = textNodeEditModal.extraData.key
            }
            if (!isPrevNodeCondition) {
                // setEdges((eds) => addEdge(newEdge, eds));
            }
            setNodes((nds) => nds.concat(newNode));
        }
        setMediaNodeEditModal({id: null, type: null})
    }

    const onSendMessageNodeAdd = (res, isEdit) => {
        if (res === '-999') {
            setSendMessageEditModal({id: null, open: false, prevId: null});

        } else {
            const {
                templateId,
                selectedLanguage,
                accountId,
                selectedMedia
            } = res || {};

            if (isEdit) {
                let nodeIndex = (nodes || []).findIndex(i => i.id === sendMessageEditModal.id)
                if (nodeIndex > -1) {
                    let _nodes = [...nodes]
                    _nodes[nodeIndex].data = {
                        templateId: templateId,
                        selectedLanguage: selectedLanguage,
                        accountId: accountId,
                        appAccountId: appAccountId,
                        selectedMediaUrl: selectedMedia,
                    };
                    setNodes([..._nodes])
                    setRender(v => v + 1)
                    setSendMessageEditModal({id: null, open: false, prevId: null});
                } else {
                    console.log('node not found')
                }
            } else {
                if (templateId === "-999") {
                    setSendMessageEditModal({id: null, open: false, prevId: null});
                    return;
                }
                if (templateId !== null && templateId !== undefined) {
                    let positionObj = {};
                    const newGeneratedId = generateId();
                    let lastPosition = nodes[nodes.length - 1].position || {
                        x: 200,
                        y: 200,
                    };
                    let newNode = {}
                    positionObj = {x: lastPosition.x + 200, y: lastPosition.y};
                    if (selectedMedia) {
                        newNode = {
                            id: newGeneratedId,
                            type: NodeTypes.SendMessage,
                            position: positionObj,
                            data: {
                                templateId: templateId,
                                selectedLanguage: selectedLanguage,
                                accountId: accountId,
                                appAccountId: appAccountId,
                                selectedMediaUrl: selectedMedia,
                            },
                        };
                    } else {
                        newNode = {
                            id: newGeneratedId,
                            type: NodeTypes.SendMessage,
                            position: positionObj,
                            data: {
                                templateId: templateId,
                                selectedLanguage: selectedLanguage,
                                accountId: accountId,
                                appAccountId: appAccountId,
                            },
                        };
                    }


                    const edgeLen = edges.length;
                    const newEdge = {
                        source:
                            DelayNodeEditModal.prevId ||
                            edges[edgeLen - 1]?.target ||
                            "1",
                        target: newGeneratedId,
                        label: 'X'
                    };

                    if (!isPrevNodeCondition) {
                        // setEdges((eds) => addEdge(newEdge, eds));
                    }

                    setNodes((nds) => nds.concat(newNode));

                    setSendMessageEditModal({id: null, open: false, prevId: null});
                    setOpenPopper({
                        isOpen: false,
                    });
                } else {
                    setSendMessageEditModal({id: null, open: true, prevId: null});
                }
            }
        }

    };

    const onTrigger = (triggerData) => {
        setTriggerData(triggerData)
    }

    const getTemplates = async (_appAccountId) => {
        try {
            const response = await fetch(
                `https://preprod-api.tbplabs.tech/whatsapp/templates?appAccountId=${_appAccountId}`,
                {
                    headers: {
                        Authorization: `Token ${Cookies.get(
                            "auth_token"
                        )}`,
                    },
                }
            );
            const responseData = await response.json();
            let _templateMap = {};
            (responseData.data || []).forEach(i => {
                _templateMap[i.id] = i.name
            })
            setTemplateMap(_templateMap);
        } catch (error) {
            console.error("Error fetching templates:", error);
        }
    };


    const nodeLen = nodes.length;

    const nodeFunctions = {
        onEdit, onAdd, isAddVisible, addCondition, onTrigger, triggerData
    };

    const nodeTypes = useMemo(
        () => ({
            [NodeTypes.Text]: withFunctions(TextNode, nodeFunctions),
            [NodeTypes.Conditional]: withFunctions(ConditionalNode, nodeFunctions),
            [NodeTypes.Delay]: withFunctions(DelayNode, nodeFunctions),
            [NodeTypes.Image]: withFunctions(ImageNode, nodeFunctions),
            [NodeTypes.Document]: withFunctions(DocumentNode, nodeFunctions),
            [NodeTypes.SendMessage]: withFunctions(SendMessageNode, {...nodeFunctions, templateMap}),
            [NodeTypes.List]: withFunctions(ListNode, nodeFunctions),
            [NodeTypes.QuickReply]: withFunctions(QuickReplyNode, nodeFunctions),
        }),
        [nodeLen, render, triggerData, templateMap]
    );

    const onConnect = useCallback(
        (params) => {
            setEdges((eds) => addEdge(params, eds));
        },
        [setEdges]
    );

// Enable drop effect on drag over
    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = "move";
    }, []);

// Handle drop event to add a new node
    const onDrop = useCallback(
        (event) => {
            event.preventDefault();

            const reactFlowBounds =
                reactFlowWrapper.current.getBoundingClientRect();
            const type = event.dataTransfer.getData("application/reactflow");

            if (typeof type === "undefined" || !type) {
                return;
            }

            const position = reactFlowInstance.project({
                x: event.clientX - reactFlowBounds?.left,
                y: event.clientY - reactFlowBounds?.top,
            });
            const newNode = {
                id: generateId(),
                type,
                position,
                data: {label: `${type}`},
            };

            console.log("Node created: ", newNode);
            setNodes((nds) => nds.concat(newNode));
        },
        [reactFlowInstance]
    );

    const onAddConditions = (conditions) => {
        if (conditions) {
            let nodeIndex = nodes.findIndex((node) => node.id === open1.id);
            if (nodeIndex > 0) {
                nodes[nodeIndex].data.conditions = conditions;
                setNodes(JSON.parse(JSON.stringify(nodes)));
            }
            setOpen1({isOpen: false, id: null});
        } else {
            setOpen1({isOpen: false, id: null});
        }
    };

    const onSaveName = async () => {
        if (workFlowName) {
            const API = "https://preprod-api.tbplabs.tech/flow/v2/flow";
            let data = {
                name: workFlowName,
                id: id
            }
            try {
                const resp = await axios.patch(API, data, auth)
                if (resp) {
                    alert('Updated successfully')
                }
            } catch (e) {

            }
            setEditName(false)

        }

    }

    return (
        <div
            style={{
                width: "calc(100vw - 240px)",
                height: "100vh",
                marginLeft: 240,
            }}
        >
            <div className='h-[80px] bg-[#cecece] py-4 px-8 flex justify-between items-center'>
                <div className='flex items-center'>
                    <div className='mr-4'>
                        {
                            editName ? <TextField
                                label="Name"
                                variant="standard"
                                style={{width: 300}}
                                onChange={e => setWorkFlowName(e.target.value)}
                                value={workFlowName}
                            /> : workFlowName
                        }
                    </div>
                    <div>
                        {editName ?
                            <div>
                                <DoneIcon className="mr-2 cursor-pointer" onClick={onSaveName}/>
                                <CloseIcon className="ml-2 cursor-pointer" onClick={e => setEditName(false)}/>
                            </div> :
                            <div>
                                <EditIcon className="cursor-pointer" onClick={e => setEditName(true)}/>
                            </div>}
                    </div>
                </div>
                <div className='flex items-center'>
                    <FormControl fullWidth className="mr-2">
                        <InputLabel id="demo-simple-select-label" shrink={true}>Select Account</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={appAccountId}
                            label="Select App"
                            size='small'
                            className={'w-[200px]'}
                            onChange={(e) => {
                                setAppAccountId(e.target.value)
                            }}
                        >
                            {appOptions.map(i => {
                                return <MenuItem value={i.id}>{i.name}t</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <Button
                        variant="contained"
                        onClick={submitFlow}
                        style={{width: 180}}
                    >
                        {id ? 'Update' : 'Submit'} Flow
                    </Button>
                </div>


            </div>
            <ReactFlow
                nodes={nodes}
                nodeTypes={nodeTypes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                onInit={setReactFlowInstance}
                onDrop={onDrop}
                onDragOver={onDragOver}
                style={rfStyle}
                onNodeClick={onNodeClick}
                onClickConnectStart={() => {
                    console.log("dot clicked");
                }}
                onPaneClick={() => {
                    setSelectedElements([]); // Reset selected elements when clicking on pane
                    setNodes((nodes) =>
                        nodes.map((n) => ({
                            ...n,
                            selected: false, // Reset selected state of nodes when clicking on pane
                        }))
                    );
                }}
                fitView
            >
                <Background variant="dots" gap={12} size={1}/>
                <Controls/>
                <MiniMap zoomable pannable/>
                <button
                    onClick={submitFlow}
                    className="p-2 px-4 bg-sky-600 rounded-lg text-white absolute z-[9999] bottom-5 right-[230px]"
                >
                    {id ? 'Update' : 'Submit'} Flow
                </button>
            </ReactFlow>

            {popperOpen.isOpen && (
                <PopperModal
                    open={true}
                    anchorEl={anchorEl}
                    onMessageNodeAdd={onMessageNodeAdd}
                    onConditionalNodeAdd={onConditionalNodeAdd}
                    onDelayNodeAdd={() => onDelayNodeAdd({min: null, hour: null, days: null})}
                    onSendMessageNodeAdd={() => onSendMessageNodeAdd(null)}
                    onNodeAdded={v => onNodeAdded(v)}
                />
            )}
            {open1.isOpen && (
                <ConditionalNodeContent
                    open={true}
                    data={open1.data}
                    handleClose={(v) => onAddConditions(v)}
                />
            )}
            {textNodeEditModal.open && (
                <TextNodeEditModal data={textNodeEditModal?.data} open={true} handleClose={onMessageUpdate}/>
            )}

            {delayNodeEditModal.open && (
                <DelayNodeEditModal data={delayNodeEditModal?.data} open={true} handleClose={onDelayNodeAdd}/>
            )}

            {mediaNodeEditModal.type === NodeTypes.Image && (
                <ImageNodeModal data={mediaNodeEditModal?.data} open={true} handleClose={onMediaNodeAdd}/>
            )}

            {mediaNodeEditModal.type === NodeTypes.Document && (
                <DocumentNodeModal data={mediaNodeEditModal?.data} open={true} handleClose={onMediaNodeAdd}/>
            )}

            {mediaNodeEditModal.type === NodeTypes.List && (
                <ListEditModal data={mediaNodeEditModal?.data} open={true} handleClose={onMediaNodeAdd}/>
            )}
            {mediaNodeEditModal.type === NodeTypes.QuickReply && (
                <QuickReplyEditModal data={mediaNodeEditModal?.data} open={true} handleClose={onMediaNodeAdd}/>
            )}

            {sendMessageEditModal.open && (
                <SendMessageEditModal
                    appAccountId={appAccountId}
                    open={true}
                    handleClose={onSendMessageNodeAdd}
                    data={sendMessageEditModal?.data}
                />
            )}
        </div>
    );
}

export default WorkflowV2;
