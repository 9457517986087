export const Variables = {
    UserInput: 'UserInput',
    Event: 'Event',
    UserName: 'UserName',
}

export const NodeTypes = {
    Text: "text",
    Conditional: "condition",
    List: "list",
    Start: "start",
    QuickReply: "QuickReply",
    Prompt: "prompt",
    Code: "code",
    Delay: "delay",
    SendMessage: "sendMessage",
    Image: 'image',
    Video: 'video',
    Document: 'document',
};


export const Operators = {
    EqualsTo: 'equal_to',
    NotEqualTo: 'not_equal_to',
}

export const ConditionTypes = {
    IF: 'IF',
    ELSE: 'ELSE',
    ELSE_IF: 'ELSE_IF',
}

export const labels = {
    'IF': 'If',
    'ELSE': 'Else',
    'ELSE_IF': 'Else If',
    // 'equal_to': 'Equals to',
    'equal_to': '==',
    'not_equal_to': 'Not Equals to',
}


export const Conjunctions = {
    OR: 'OR',
    AND: 'AND',
}

export const generateId = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let uniqueString = '';
    for (let i = 0; i < 8; i++) {
        uniqueString += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return uniqueString;
}

// Initial node setup
export const initialNodes = [
    {
        id: "1",
        type: NodeTypes.Text,
        data: {label: "input nodes"},
        position: {x: 250, y: 5},
    },
];
