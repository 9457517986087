import {Fade, Popper} from "@mui/material";
import Paper from "@mui/material/Paper";
import {SimpleTreeView} from "@mui/x-tree-view/SimpleTreeView";
import {TreeItem} from "@mui/x-tree-view/TreeItem";
import React from "react";
import {NodeTypes} from "./constants";

export const PopperModal = ({
                                open,
                                anchorEl,
                                onMessageNodeAdd,
                                onConditionalNodeAdd,
                                onDelayNodeAdd,
                                onSendMessageNodeAdd,
                                onNodeAdded,
                            }) => {


    return (
        <Popper
            open={open}
            anchorEl={anchorEl}
            placement={"right-start"}
            transition
        >
            {({TransitionProps}) => (
                <Fade {...TransitionProps} timeout={350}>
                    <Paper>
                        <SimpleTreeView>
                            <TreeItem itemId="grid" label="Message">
                                <TreeItem
                                    itemId={NodeTypes.Text}
                                    label="Text"
                                    onClick={onMessageNodeAdd}
                                />
                                <TreeItem
                                    onClick={onSendMessageNodeAdd}
                                    itemId={NodeTypes.SendMessage}
                                    label="Send Message"
                                />
                                <TreeItem
                                    itemId={NodeTypes.Image}
                                    label="Image"
                                    onClick={() => onNodeAdded(NodeTypes.Image)}/>
                                <TreeItem
                                    itemId={NodeTypes.Document}
                                    label="Document"
                                    onClick={() => onNodeAdded(NodeTypes.Document)}/>
                                {/*<TreeItem itemId="grid-premium" label="Video"/>*/}
                                <TreeItem
                                    itemId={NodeTypes.List}
                                    label="List"
                                    onClick={() => onNodeAdded(NodeTypes.List)}/>
                                <TreeItem
                                    itemId={NodeTypes.QuickReply}
                                    label="Quick Reply"
                                    onClick={() => onNodeAdded(NodeTypes.QuickReply)}/>
                            </TreeItem>
                            <TreeItem itemId="pickers" label="Action">
                                <TreeItem
                                    itemId={NodeTypes.Conditional}
                                    label="Conditional"
                                    onClick={onConditionalNodeAdd}
                                />
                                <TreeItem
                                    onClick={onDelayNodeAdd}
                                    itemId={NodeTypes.Delay}
                                    label="Delay"
                                />
                            </TreeItem>
                        </SimpleTreeView>
                    </Paper>
                </Fade>
            )}
        </Popper>
    );
};
