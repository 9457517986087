import React from 'react';
import {APIs} from "../apis/urls";
import {postData} from "../apis/api";
import {formatComponentStack} from "../utils";
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }


    componentDidCatch(error, errorInfo) {
        const formattedStack = formatComponentStack(errorInfo.componentStack);
        const errorData = {
            error: error.toString(),
            errorInfo: formattedStack
        }
        postData(APIs.error, errorData, {})
    }

    render() {
        // if (this.state.hasError) {
        //     // You can render any custom fallback UI
        //     return <h1>Loading ...</h1>;
        // }

        return this.props.children;
    }
}

export default ErrorBoundary
