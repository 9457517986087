import React, {useState} from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Input from "@mui/material/Input";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {ConditionTypes, Conjunctions, generateId, labels, NodeTypes, Operators, Variables} from "../constants";
import {Tooltip} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import EditIcon from "@mui/icons-material/Edit";
import {HandleDot} from "../handle";
import {Handle, Position} from "reactflow";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const borderCss = {border: "1px solid #cecece", borderRadius: 4};

export const initialInnerCondition = {
    "conjunction": "",
    "variable": Variables.UserInput,
    "operator": "equal_to",
    "value": "",
    "id": 0
}

const _elseCondition = {
    "conjunction": "",
    "variable": Variables.UserInput,
    "showAdd": true,
    "operator": "equal_to",
    "value": "",
    "id": 0
}


export const initialCondition = [
    {
        "type": ConditionTypes.IF,
        "id": "3",
        "nodeId": "327c6e34",
        "showAdd": true,
        "condition": [
            initialInnerCondition
        ]
    },
    // {
    //     "type": ConditionTypes.ELSE,
    //     "id": "4",
    //     "nodeId": "327c6e34"
    // }
]

const elseIfCondition = {
    "type": ConditionTypes.ELSE_IF,
    "id": "3",
    "nodeId": generateId(),
    "showAdd": true,
    "condition": [
        initialInnerCondition
    ]
}

export const ConditionalNodeContent = ({open, handleClose, data}) => {

    const [conditions, setConditions] = useState(data || [])
    const [elseCondition, setElseCondition] = useState(_elseCondition)

    const addSubCondition = (index, subIndex) => {
        let newConditions = [...conditions]
        newConditions[index].condition[subIndex].conjunction = Conjunctions.OR
        newConditions[index].condition.push(initialInnerCondition)
        setConditions(newConditions)
    }
    const onConjunctionChange = (index, subIndex, value) => {
        let newConditions = [...conditions]
        newConditions[index].condition[subIndex].conjunction = value
        setConditions(newConditions)
    }

    const onAddCondition = (index) => {
        // setConditions(conditions.splice(index,0, elseIfCondition))
        conditions.push(elseIfCondition)
        setConditions([...conditions])
    }

    const onVariableChange = (index, subIndex, key, value) => {
        let newConditions = JSON.parse(JSON.stringify(conditions))
        newConditions[index].condition[subIndex][key] = value
        setConditions(newConditions)
    }

    return (
        <Modal
            open={open}
            onClose={v => handleClose(null)}
        >
            <Box sx={style} style={{overflow: 'auto', height: 500}}>
                <div>Set conditions</div>

                {
                    conditions.map((condition, index) => {
                        return <div style={borderCss} className='mt-2 p-2'>
                            <div>{labels[condition.type] || condition.type}</div>
                            {
                                condition.condition.map((cc, subIndex) => {
                                    return (
                                        <div className='mt-2'>
                                            <div className='m-2 p-2'>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Select
                                                        Variable</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={cc.variable}
                                                        label="Select Variable"
                                                        size='small'
                                                        onChange={(e) => {
                                                            onVariableChange(index, subIndex, 'variable', e.target.value)
                                                        }}
                                                    >
                                                        <MenuItem value={Variables.UserInput}>User Input</MenuItem>
                                                        <MenuItem value={Variables.Event}>Event</MenuItem>
                                                        <MenuItem value={Variables.UserName}>User Name</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className='m-2 p-2'>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Operator</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        label="Operator"
                                                        value={cc.operator}
                                                        size='small'
                                                        onChange={(e) => {
                                                            onVariableChange(index, subIndex, 'operator', e.target.value)
                                                        }}
                                                    >
                                                        <MenuItem value={Operators.EqualsTo}>Equal To</MenuItem>
                                                        <MenuItem value={Operators.NotEqualTo}>Not Equal To</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className='m-2 p-2'>
                                                <FormControl fullWidth className='m-2 p-2'>
                                                    <InputLabel id="demo-simple-select-label">Enter value</InputLabel>
                                                    <Input size='small'
                                                           value={cc.value}
                                                           onChange={(e) => {
                                                               onVariableChange(index, subIndex, 'value', e.target.value)
                                                           }}/>
                                                </FormControl>
                                            </div>
                                            {
                                                !!cc.conjunction &&
                                                <div style={{textAlign: 'center'}}>
                                                    <InputLabel id="demo-simple-select-label">Operator</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={cc.conjunction}
                                                        label="Consuction"
                                                        size='small'
                                                        onChange={e => {
                                                            onConjunctionChange(index, subIndex, e.target.value)
                                                        }}
                                                    >
                                                        <MenuItem value={Conjunctions.AND}>{Conjunctions.AND}</MenuItem>
                                                        <MenuItem value={Conjunctions.OR}>{Conjunctions.OR}</MenuItem>
                                                    </Select>
                                                </div>
                                            }
                                            {subIndex === (condition.condition.length - 1) &&
                                                <Button onClick={() => addSubCondition(index, subIndex)}>Add
                                                    expression</Button>}
                                        </div>
                                    )
                                })
                            }
                            <Button onClick={() => onAddCondition(index)}>Add condition</Button>
                        </div>
                    })
                }
                <div className="m-2">
                    <Button onClick={() => {
                        handleClose(conditions)
                    }} variant="contained">Submit</Button>
                </div>
            </Box>
        </Modal>
    );
}

export const ConditionalNode = (props) => {
    const {onClick, data, selected, onEdit, onAdd, isAddVisible, addCondition, ...rest} = props

    const handleCount = data.conditions.length;

    return (
        <div
            className={` shadow-md rounded-md bg-white   ${
                selected
                    ? "border-solid border-2 border-indigo-500/100"
                    : ""
            } `}
        >
            <div className="flex flex-col">
                <div
                    className="max-h-max px-2 py-1 text-left text-black text-xs font-bold rounded-t-md bg-teal-300">
                    Condition
                    <Tooltip title={rest.id}>
                        <ErrorOutlineIcon style={{fontSize: 10}}/>
                    </Tooltip>
                    <Tooltip className='ml-2' title='Edit'>
                        <EditIcon style={{fontSize: 12}}
                                  onClick={() => onEdit(props)}/>
                    </Tooltip>
                </div>
                <div className="px-3 py-2  text-xs">
                    <div className="px-3 py-2 text-xs">
                        {(data.conditions || []).map((condition, index) => {
                            const angleStep = (2 * Math.PI) / handleCount;
                            const angle = index * angleStep;
                            const y = Math.sin(angle) * 20 - index * 10; // Adjust the radius as needed
                            const key = `source-${index}`
                            return (
                                <div
                                    style={borderCss}
                                    className="mt-2 flex m-2 p-2 items-center justify-around"
                                >
                                    <div>
                                        <div> {labels[condition.type] || condition.type}</div>
                                        <HandleDot
                                            // addVisible={condition.showAdd}
                                            addVisible={true}
                                            type="source"
                                            onAdd={(v) =>
                                                onAdd(
                                                    v,
                                                    rest,
                                                    rest.id,
                                                    {type: NodeTypes.Conditional, key, conditionId: condition.nodeId}
                                                )
                                            }
                                            key={key}
                                            // position={Position.Right}
                                            id={key}
                                            style={{
                                                background: "#555",
                                                borderRadius: "50%",
                                                width: 0,
                                                height: 0,
                                                top: `${
                                                    (index + 1) * 80
                                                }px`,
                                                left: "auto",
                                                right: -4,
                                                position: "absolute",
                                            }}
                                        />
                                    </div>
                                    <div>
                                        {(condition.condition || []).map(
                                            (cc, subIndex) => {
                                                return (
                                                    <div
                                                        className="p-2"
                                                        onClick={() =>
                                                            addCondition(
                                                                rest.id,
                                                                data.conditions
                                                            )
                                                        }
                                                    >
                                                        <div>
                                                            <div className="text-sky-400">
                                                                {
                                                                    cc.variable
                                                                }
                                                            </div>
                                                            <div>
                                                                {" "}
                                                                {
                                                                    labels[cc.operator] || cc.operator
                                                                }
                                                            </div>
                                                            <div className="text-sky-400">
                                                                {cc.value}
                                                            </div>
                                                        </div>
                                                        {!!cc.conjunction && (
                                                            <div
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                }}
                                                            >
                                                                <div className="text-sky-400">
                                                                    {
                                                                        cc.conjunction
                                                                    }
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div
                        className="flex m-2 p-2 bg-[#f9fafb]"
                        style={borderCss}
                    >
                        <div>else default</div>
                        <HandleDot
                            // addVisible={data.showElseAdd}
                            addVisible={true}
                            id={"c"}
                            type="source"
                            onAdd={(v) => onAdd(v, rest, rest.elseId,
                                {type: NodeTypes.Conditional, key: 'else'})}
                            position={Position.Right}
                            style={{
                                background: "#555",
                                borderRadius: "50%",
                                width: 1,
                                height: 1,
                                top: 80 + data.conditions.length * 80,
                                left: "auto",
                                right: -4,
                                position: "absolute",
                            }}
                        />
                    </div>
                </div>
                <Handle
                    style={{position: "absolute", left: -4}}
                    id={"c"}
                    type="target"
                    position={Position.Left}
                />
            </div>
        </div>
    );
};
