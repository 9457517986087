import React,{useState} from 'react'

const CommunicateContext = React.createContext({
  objectid: '',
  setObject: ()=>{},
})

export const CommunicateContextProvider = ({children}) => {
  const [objectid, setObjectid] = useState('')

  const setObject = (id) => {
    setObjectid(id)
  }

  const contextValue = {
    objectid: objectid,
    setObject: setObject,
  }

  return (
    <CommunicateContext.Provider value={contextValue}>
      {children}
    </CommunicateContext.Provider>
  )
}

export default CommunicateContext
