import React, {useContext, useEffect, useState} from "react";
import Modal from "react-modal";
import axios from "axios";
import ImageIcon from "@mui/icons-material/Image";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import VideoCameraBackIcon from "@mui/icons-material/VideoCameraBack";
import CommunicateContext from "../../store/CommunicateContext";
import {CircularProgress} from "@mui/material";
import {getMeApi} from "../utils/components/MeApi";

Modal.setAppElement("#root");
const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "70%",
        height: "70vh",
        position: "relative",
        padding: "0px 0px",
    },
    overlay: {
        backgroundColor: "rgba(1,1,1,0.4)",
        backdropFilter: "blur(4px)",
        zIndex: 10000,
    },
    dropdown: {
        border: "1px solid gainsboro",
        padding: "8px",
        borderRadius: "7px",
    },
    inputRow: {
        display: "flex",
        justifyContent: "space-evenly",
    },
};
const SelectTemplateModal = (props) => {
    const communicateCtx = useContext(CommunicateContext);
    const {openModal, closeModal} = props;
    const [templateIndex, setTemplateIndex] = useState("null");
    const [bodyT, setBodyT] = useState("");
    const [footerT, setFooterT] = useState("");
    const [headerVariable, setHeaderVariable] = useState("");
    // const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [variables, setVariables] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [webButtonVariableValue, setWebButtonVariableValue] = useState();
    const [meData, setMeData] = useState({})

    let subtitle = {
        style: {}
    };
    let headerUrl = "";

    function afterOpenModal() {
        subtitle.style.color = "#f00";
    }

    function onTemplateSelect(e) {
        setHeaderVariable("");
        setTemplateIndex(e.target.value);
        let index = e.target.value;
        if (index != "null") {
            props.setSelectedTemplate(props.templates[index]);
            props.setTemplateName(props.templates[index].name)
            let varData = [];
            let body = "";
            let footer = "";
            for (let i = 0; i < props.templates[index].components.length; i++) {
                if (props.templates[index].components[i].type === "BODY") {
                    body = props.templates[index].components[i].text;
                }
                if (props.templates[index].components[i].type === "FOOTER") {
                    footer = props.templates[index].components[i].text;
                }
            }
            setBodyT(body);
            setFooterT(footer);
            for (let i = 1; i <= props.templates[index].variableCount; i++) {
                varData.push("");
            }
            setVariables(varData);
        } else {
            setBodyT("");
            setFooterT("");
            props.setSelectedTemplate(null);
        }
    }

    async function onSend() {
        setIsLoading(true);
        if (
            props.selectedTemplate.headerMediaType != "TEXT" &&
            props.selectedTemplate.headerMediaType != "" &&
            selectedFile != null
        ) {
            await uploadFile();
        }
        let reqBody = {
            action: "SendMessageToCustomer",
            data: {
                userid: props.userid,
                accountid: props.appAccountId,
                appAccountId: props.appAccountId,
                objectid: communicateCtx.objectid,
                templateId: props.selectedTemplate.templateId,
                variables: variables,
                headerMediaType: props.selectedTemplate.headerMediaType,
                headerMediaURL: headerUrl,
                headerText: headerVariable,
                isPortal: true,
                webVariableValue: webButtonVariableValue,
            },
        };

        props.ws.send(JSON.stringify(reqBody));
        setIsLoading(false);
        closeModal();
        setHeaderVariable("");
    }

    function changeVariableValue(e, varNo) {
        let temp = [...variables];
        temp[varNo] = e.target.value;
        setVariables(temp);
    }

    const [selectedFile, setSelectedFile] = useState(null);

    const getMeApiData =async ()=> {
        const response = await getMeApi();
        if(response?.data?.message){
            setMeData(response.data.message)
        }
    }

    useEffect(()=> {
      getMeApiData()

    },[])

    const uploadFile = async (file) => {
        const formData = new FormData();
        formData.append("file", file);
        try {
            setIsLoading(true);
            const response = await axios({
                method: "post",
                url: `https://preprod-api.tbplabs.tech/chatapp/uploadFile?accountId=${(meData.account?.id || props.accountid)}&userId`,
                data: formData,
                headers: {"Content-Type": "multipart/form-data"},
            });
            headerUrl = response.data.data.fileUrl;
            props.setSelectedMedia(response.data.data.fileUrl)

        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };

    const handleFileSelect = (event) => {
        setSelectedFile(event.target.files[0]);
        uploadFile(event.target.files[0]);
    };

    return (
        <div>
            <Modal
                isOpen={openModal}
                onAfterOpen={afterOpenModal}
                onRequestClose={v => closeModal(false)}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="flex w-full justify-between items-center h-[10%] px-[16px]">
                    <div className="font-bold">
                        Send Template Message to Contact
                        {props.profile != null
                            ? props.profile.Data.object.phone
                            : null}
                    </div>
                    <div className="cursor-pointer" onClick={v => closeModal(false)}>
                        x
                    </div>
                </div>
                <hr/>
                <div className="flex w-full justify-between items-center relative h-[80%] px-[16px] py-[16px]">
                    <div
                        className="circle_cont"
                        style={{
                            display: isLoading ? "flex" : "none",
                        }}
                    >
                        <svg
                            className="svg"
                            viewBox="0 0 100 100"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle
                                cx="50"
                                cy="50"
                                r="30"
                                fill="transparent"
                                stroke-width="8px"
                                strokeDasharray="160"
                            />
                        </svg>
                    </div>
                    <div className="self-start">
                        <div className="font-semibold py-[10px]">
                            Select Template
                        </div>
                        <select
                            style={customStyles.dropdown}
                            value={templateIndex}
                            onChange={(e) => {
                                onTemplateSelect(e);
                            }}
                            className="my-[16px]"
                        >
                            <option value={"null"}>Select Template</option>
                            {props.templates?.map((template, index) => (
                                <option key={index} value={index}>
                                    {template.name}
                                </option>
                            ))}
                        </select>

                        <div
                            className="temp-variables"
                            style={{
                                display:
                                    templateIndex !== "null" ? "block" : "none",
                            }}
                        >
                            {variables.map((variable, index) => (
                                <div
                                    style={customStyles.inputRow}
                                    className="my-[16px]"
                                >
                                    <label>Var {index + 1} </label>
                                    <input
                                        placeholder="custom variable"
                                        type="text"
                                        value={variables[index]}
                                        onChange={(e) => {
                                            changeVariableValue(e, index);
                                        }}
                                        // onClick="this.select();"
                                        style={customStyles.dropdown}
                                    />
                                    or
                                    <select
                                        className="border-sky-600 bg-sky-600 text-white rounded px-3 text-sm"
                                        onChange={(e) =>
                                            changeVariableValue(e, index)
                                        }
                                    >
                                        <option value="--">
                                            Choose Pre-defined values
                                        </option>
                                        {props.contactFormData.map((e) =>
                                            e.fields.map((label) => (
                                                <option
                                                    value={label.internalName}
                                                >
                                                    {label.label}
                                                </option>
                                            ))
                                        )}
                                    </select>
                                </div>
                            ))}
                            <div>
                                {props.selectedTemplate &&
                                    props.selectedTemplate
                                        .webButtonVariableCount === 1 && (
                                        <div className="flex gap-6 mt-2 ml-12 pl-1">
                                            <p>Web Button:</p>
                                            <input
                                                type="text"
                                                name="webButtonVarible"
                                                style={customStyles.dropdown}
                                                onChange={(e) =>
                                                    setWebButtonVariableValue(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </div>
                                    )}
                            </div>
                            <div
                                style={{
                                    display:
                                        props.selectedTemplate &&
                                        props.selectedTemplate
                                            .headerMediaType !== "TEXT" &&
                                        props.selectedTemplate
                                            .headerMediaType !== ""
                                            ? "block"
                                            : "none",
                                }}
                            >
                                <div className="font-semibold py-[10px]">
                                    Select Header{" "}
                                    {props.selectedTemplate &&
                                    props.selectedTemplate.headerMediaType ===
                                    "IMAGE"
                                        ? "Image"
                                        : props.selectedTemplate &&
                                        props.selectedTemplate
                                            .headerMediaType === "VIDEO"
                                            ? "Video"
                                            : "File"}
                                </div>
                                <input
                                    type="file"
                                    onChange={handleFileSelect}
                                    accept={
                                        props.selectedTemplate &&
                                        props.selectedTemplate
                                            .headerMediaType === "IMAGE"
                                            ? "image/*"
                                            : props.selectedTemplate &&
                                            props.selectedTemplate
                                                .headerMediaType === "VIDEO"
                                                ? "video/*"
                                                : ""
                                    }
                                />
                            </div>
                            {isLoading && (
                                <div className="w-full mx-auto flex justify-center">
                                    <CircularProgress/>
                                </div>
                            )}

                            <div
                                style={{
                                    display:
                                        props.selectedTemplate &&
                                        props.selectedTemplate
                                            .headerVariable === true
                                            ? "block"
                                            : "none",
                                }}
                            >
                                <div className="font-semibold py-[10px]">
                                    Header Variable
                                </div>
                                <input
                                    type="text"
                                    value={headerVariable}
                                    style={customStyles.dropdown}
                                    onChange={(e) => {
                                        setHeaderVariable(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="preview-template w-2/5">
                        <div className="bgImg">
                            <div className="chat-bubbles">
                                {props.selectedTemplate &&
                                props.selectedTemplate.headerMediaType ===
                                "IMAGE" ? (
                                    <div className="sample-image">
                                        <ImageIcon></ImageIcon>
                                    </div>
                                ) : null}
                                {props.selectedTemplate &&
                                props.selectedTemplate.headerMediaType ===
                                "DOCUMENT" ? (
                                    <div className="sample-file">
                                        <InsertDriveFileIcon></InsertDriveFileIcon>
                                    </div>
                                ) : null}
                                {props.selectedTemplate &&
                                props.selectedTemplate.headerMediaType ===
                                "VIDEO" ? (
                                    <div className="sample-file">
                                        <VideoCameraBackIcon></VideoCameraBackIcon>
                                    </div>
                                ) : null}

                                <div className="mb-[10px]">{bodyT}</div>
                                <div className="text-gray-400">{footerT}</div>
                                <div className="bubble-arrow"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr/>
                <div className="flex w-full justify-end items-center h-[10%] px-[16px]">
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mx-4 rounded"
                        onClick={() => {
                            closeModal(true);
                        }}
                    >
                        {props.isEdit ? 'Update' : 'Add'}
                    </button>
                    <button
                        className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
                        onClick={v => closeModal(false)}
                    >
                        Close
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default SelectTemplateModal;
