import React, {useEffect, useState} from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {Card, SwipeableDrawer, TextareaAutosize, Tooltip} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import EditIcon from "@mui/icons-material/Edit";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {Handle, Position} from "reactflow";
import {HandleDot} from "../handle";
import InputLabel from "@mui/material/InputLabel";
import Divider from '@mui/material/Divider';
import AddBoxIcon from '@mui/icons-material/AddBox';
import TextField from "@mui/material/TextField";

export const TextNodeEditModal = ({open, handleClose, data}) => {

    const isEditMode = !!data?.id

    const [text, setText] = useState(isEditMode ? data.data?.label : '')

    const onAdd = () => {
        handleClose(text, isEditMode)
    }
    const onClose = () => {
        handleClose()
    }
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    return (<Modal
        open={open}
        onClose={onClose}
    >
        <Box sx={style}>
            <div>Enter some text for User</div>
            <div className='m-2'></div>
            <TextareaAutosize
                className="p-2"
                value={text}
                onChange={e => setText(e.target.value)}
                style={{border: '1px solid #cecece', borderRadius: 4, width: 300}}
            />
            <div className='m-2'></div>
            <Button variant="contained" onClick={onAdd}
                    disabled={!text.length}>{isEditMode ? 'Update' : 'Add'}</Button>
            <Button style={{marginLeft: 12}} variant="outlined" onClick={onClose}>Close</Button>
        </Box>
    </Modal>);
}

export const TextNode = (props) => {

    const {data, selected, onEdit, onAdd, isAddVisible, onTrigger, triggerData = [], ...rest} = props

    const [contactFormData, setContactFormData] = useState([])
    const [open, setOpen] = useState(false)


    useEffect(() => {
        fetchData2()
    }, []);

    function getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(";");
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == " ") c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    const fetchData2 = () => {
        let myHeaders = new Headers();
        const name = "auth_token";
        let authtoken = getCookie(name);
        authtoken = "Token " + authtoken;
        myHeaders.append("Authorization", authtoken);

        var requestOptions = {
            method: "GET", headers: myHeaders, redirect: "follow",
        };
        fetch("https://preprod-api.tbplabs.tech/contacts/Contactform", requestOptions)
            .then((res) => res.json())
            .then((data) => {
                setContactFormData(data.data);
            });
    }

    const onCloseModal = (d) => {
        if (d) {
            onTrigger(d)
        }
        setOpen(false)
    }

    return (<div
        className={`w-40  shadow-md rounded-md bg-white   ${selected ? "border-solid border-2 border-indigo-500/100" : ""} `}>
        <div className="flex flex-col">
            <div
                className="max-h-max px-2 py-1 text-left text-black text-xs font-bold rounded-t-md bg-teal-300">
                {props.id === '1' ? "Starting Node" : 'Text'}
                <Tooltip title={rest.id}>
                    <ErrorOutlineIcon style={{fontSize: 10}}/>
                </Tooltip>
                <Tooltip className='ml-2' title='Edit'>
                    <EditIcon style={{fontSize: 12}}
                              onClick={() => onEdit(props)}/>
                </Tooltip>
            </div>
            {props.id === '1' && <div style={{margin: 24}}>
                <Button variant="contained"
                        style={{fontSize: 10, padding: 6}}
                        onClick={() => {
                            setOpen(!open)
                        }}>
                    {triggerData?.length ? 'Update Event' : 'No event'}
                </Button>
            </div>}
            {props.id !== '1' && <div style={{fontSize: 10, padding: 6}}>
                {data?.label}
            </div>}
        </div>

        {props.id !== '1' && <Handle id="b" type="target" position={Position.Left}/>}
        <HandleDot
            // addVisible={isAddVisible(rest)}
            addVisible={true}
            id="b"
            type="source"
            position={Position.Right}
            onAdd={(v) => onAdd(v, rest)}
        />
        {open && <Drawer data={contactFormData} open={true} onClose={onCloseModal} triggerData={triggerData}/>}
    </div>);
};


export const Drawer = ({open, data, onClose, triggerData}) => {

    const [options, setOptions] = useState([])
    const [mainData, setMainData] = useState(triggerData)
    const [count, setCount] = useState(1)

    useEffect(() => {
        setTimeout(() => {
            setCount(2)
        }, 500)
    }, [])


    useEffect(() => {
        if (mainData.length && count === 2) {
            let allItems = []
            data.forEach(i => {
                allItems = allItems.concat(i.fields)
            })
            let _mainData = [...mainData]
            mainData.forEach((ii, index) => {
                ii.forEach((i, cIndex) => {
                    if (i.type === 'SINGLECHOICE') {
                        let item = allItems.find(j => j.id === i.id)
                        if (item) {
                            _mainData[index][cIndex].options = (item.list || []).map(k => ({
                                value: k.id,
                                label: k.label
                            }))

                        }
                    }
                })
            })
            setMainData(_mainData)
        }
    }, [count])

    useEffect(() => {
        let _options = []
        if (data.length) {
            (data || []).forEach(d => {
                (d.fields || []).forEach(field => {
                    _options.push({value: field.id, label: field.label})
                })
            })
        }
        setOptions(_options)
    }, [data])

    const onFieldChange = (val, pIndex, cIndex) => {
        let allItems = []
        data.forEach(i => {
            allItems = allItems.concat(i.fields)
        })
        let _mainData = [...mainData]
        _mainData[pIndex][cIndex].id = val
        _mainData[pIndex][cIndex].options = val
        let item = allItems.find(j => j.id === val)
        if (item) {
            _mainData[pIndex][cIndex].type = item.fieldType
            _mainData[pIndex][cIndex].options = (item.list || []).map(k => ({
                value: k.id,
                label: k.label
            }))
        }
        _mainData[pIndex][cIndex].value = {
            id: ''
        }
        setMainData(_mainData)
    }

    const onValueChange = (val, pIndex, cIndex) => {
        let _mainData = [...mainData]
        if (_mainData[pIndex][cIndex].type === 'TEXT') {
            if (_mainData[pIndex][cIndex].value) {
                _mainData[pIndex][cIndex].value.value = val
            } else {
                _mainData[pIndex][cIndex].value = {value: val}
            }
        } else {
            _mainData[pIndex][cIndex].value.id = val
        }
        setMainData(_mainData)
    }

    const onPlusClick = (pIndex) => {
        let _mainData = [...mainData]
        _mainData[pIndex] = [..._mainData[pIndex], {
            id: null,
            "type": "SINGLECHOICE",
            "value": {
                id: null,
            }
        },]
        setMainData(_mainData)
    }

    const onAddCondition = () => {
        setMainData([...mainData, [
            {
                "id": null,
                "type": "",
                "value": {
                    "id": null,
                }
            }
        ]])
    }

    const onSave = () => {
        onClose(mainData)
    }

    return (<SwipeableDrawer
        anchor={'right'}
        open={open}
        onClose={() => {
            onClose(null)
        }}
        onOpen={() => {
        }}

    >
        <div style={{marginBottom: 12, marginTop: 12, marginLeft: 36, fontSize: 18, minWidth: 300}}>
            <b>Events</b>
        </div>
        <div style={{margin: 36}}>
            {
                (mainData || []).map((ii, index) => {
                    return <Card style={{padding: 16, marginBottom: 16}}>
                        {
                            ii.map((i, cIndex) => {
                                return <div>
                                    <div>
                                        <InputLabel id="demo-multiple-name-label">Select Field</InputLabel>
                                        <Select
                                            labelId="demo-multiple-name-label"
                                            value={i.id}
                                            onChange={(e) => {
                                                onFieldChange(e.target.value, index, cIndex)
                                            }}
                                            style={{width: 300, height: 35, marginBottom: 12}}
                                            label="Select"
                                            placeholder={"Select"}
                                        >
                                            {options.map(field => <MenuItem
                                                value={field.value}>{field.label}</MenuItem>)}
                                        </Select>
                                    </div>
                                    {i.type === 'SINGLECHOICE' &&
                                        <div>
                                            <InputLabel id="demo-multiple-name-label">Select Value</InputLabel>
                                            <Select
                                                value={i.value.id}
                                                onChange={(e) => {
                                                    onValueChange(e.target.value, index, cIndex)
                                                }}
                                                style={{width: 300, height: 35, marginBottom: 12}}
                                                label="Select Event"
                                            >
                                                {(i?.options || []).map(i => {
                                                    return <MenuItem value={i.value}>{i.label}</MenuItem>
                                                })}
                                            </Select>
                                        </div>
                                    }
                                    {i.type === 'TEXT' &&
                                        <div>
                                            <InputLabel id="demo-multiple-name-label">Select Value</InputLabel>
                                            <TextField
                                                value={i.value?.value || ''}
                                                onChange={e => {
                                                    onValueChange(e.target.value, index, cIndex)
                                                }}
                                                className='mt-2'
                                                label="Input Value"
                                                style={{width: 300, height: 25, marginBottom: 12}}
                                                variant="outlined"
                                            />
                                        </div>
                                    }
                                    {((cIndex + 1) !== ii.length) && <Divider style={{margin: 12}}/>}
                                    {
                                        ((cIndex + 1) === ii.length) &&
                                        <div className='mt-8 text-center cursor-pointer'>
                                            <AddBoxIcon onClick={e => onPlusClick(index)}/>
                                        </div>
                                    }
                                </div>
                            })
                        }
                    </Card>
                })
            }
            {
                <div className='text-center cursor-pointer'>
                    <Button variant="contained"
                            onClick={e => onAddCondition()}
                            icon={<AddBoxIcon/>}>Add condition</Button>
                </div>
            }
        </div>
        <>
            <Divider style={{margin: 12}}/>
            <div className='flex justify-center'>
                {!!mainData?.[0]?.[0]?.id &&
                    <Button variant="contained" onClick={() => {
                        onSave()
                    }}>Save</Button>
                }
                <Button variant="contained" style={{marginLeft: 12}} onClick={() => {
                    onClose(null)
                }}>Close</Button>
            </div>
        </>


    </SwipeableDrawer>)
}
