// withFunctions.jsx
import React from 'react';

// HOC to inject multiple functions as props
const withFunctions = (Component, functions) => {
    return (props) => {
        return <Component {...props} {...functions} />;
    };
};

export default withFunctions;
