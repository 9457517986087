import React, {useState} from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {CircularProgress, FormControlLabel, Radio, RadioGroup, Tooltip} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import EditIcon from "@mui/icons-material/Edit";
import {Handle, Position} from "reactflow";
import {HandleDot} from "../handle";
import TextField from "@mui/material/TextField";
import Input from "@mui/material/Input";
import Typography from "@mui/material/Typography";
import {NodeTypes} from "../constants";
import {uploadFileToS3} from "../../../utils";
import AttachFileIcon from '@mui/icons-material/AttachFile';

export const DocumentNodeModal = ({open, handleClose, data}) => {

    const _data = data?.data || {}

    const isEdit = !!data?.id
    const [type, setType] = useState('url')
    const [mediaURL, setURL] = useState(isEdit ? _data.mediaURL : "")
    const [caption, setCaption] = useState(isEdit ? _data.caption : "")
    const [uploadedFile, setUploadedFile] = useState(false)
    const [uploadLoading, setUploadLoading] = useState(false)


    const [preview, setPreview] = useState(null);

    const handleDocumentChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setURL(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const onAdd = () => {
        handleClose(NodeTypes.Document, {mediaURL, caption, type: NodeTypes.Document}, isEdit);
    };
    const onClose = () => {
        handleClose(NodeTypes.Document, null, isEdit);
    };

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 500,
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
    };

    const onTypeChange = (v) => {
        setType(v)
        setPreview('')
        setURL('')
        setUploadedFile(false)
        setCaption('')
    }

    const onUpload = () => {
        uploadFileToS3(mediaURL, setUploadLoading).then(res => {
            setURL(res)
        })
    }

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={style}>
                <h1 className="font-bold text-sky-600 text-2xl">Document</h1>
                <RadioGroup row value={type} onChange={e => onTypeChange(e.target.value)}>
                    <FormControlLabel value="url" control={<Radio/>} label="Select URL"/>
                    <FormControlLabel value="upload" control={<Radio/>} label="Upload from device"/>
                </RadioGroup>
                <div className="mt-3">
                    {
                        type === 'url' ? <>
                                <TextField
                                    sx={{width: 350}}
                                    label={'Paste URL'}
                                    onChange={(e) => setURL(e.target.value)}
                                    value={mediaURL}
                                />
                            </> :
                            <div>
                                <Input
                                    accept=".pdf, .doc, .docx, .xls, .xlsx, .txt, .rtf, .odt, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain, application/rtf, application/vnd.oasis.opendocument.text"
                                    id="document-upload"
                                    type="file"
                                    onChange={handleDocumentChange}
                                />
                                <div className='mt-4'>
                                    <Button variant="contained"
                                            onClick={onUpload}
                                            component="span" disabled={uploadedFile}>
                                        Upload Document {uploadLoading && <CircularProgress/>}
                                    </Button>
                                </div>
                                {uploadedFile && <div>Document Uploaded Successfully</div>}
                                {preview && (
                                    <Box mt={2} textAlign="center" className="mt-2">
                                        <Typography variant="h6">Document Preview:</Typography>
                                        <Box
                                            component="img"
                                            src={preview}
                                            alt="Preview"
                                            sx={{width: '300px', height: 'auto', mt: 2, borderRadius: 2}}
                                        />
                                    </Box>
                                )}
                            </div>
                    }

                    <div className='mt-4'>
                        <TextField
                            sx={{width: 350}}
                            label={'Caption'}
                            onChange={(e) => setCaption(e.target.value)}
                            value={caption}
                        />
                    </div>

                </div>
                <div className='mt-4'>
                    <Button
                        disable={type === 'url' ? mediaURL === '' : !uploadedFile}
                        variant="contained"
                        onClick={onAdd}
                    >
                        {isEdit ? 'Update' : 'Add'}
                    </Button>
                    <Button
                        style={{marginLeft: 12}}
                        variant="outlined"
                        onClick={onClose}
                    >
                        Close
                    </Button>
                </div>
            </Box>
        </Modal>
    );
};

export const DocumentNode = (props) => {
    const {data, selected, onEdit, onAdd, isAddVisible, ...rest} = props;

    return (
        <div
            className={`w-40  shadow-md rounded-md bg-white   ${
                selected
                    ? "border-solid border-2 border-indigo-500/100"
                    : ""
            } `}
        >
            <div className="flex flex-col">
                <div
                    className="max-h-max px-2 py-1 text-left text-black text-xs font-bold rounded-t-md bg-teal-300">
                    Document <Tooltip title={rest.id}>
                    <ErrorOutlineIcon style={{fontSize: 10}}/>
                </Tooltip>

                    <Tooltip className='ml-2' title='Edit'>
                        <EditIcon style={{fontSize: 12}}
                                  onClick={() => onEdit(props)}/>
                    </Tooltip>
                </div>
                <div className="px-3 py-2 ">
                    <div className="text-xs font-normal text-black">
                        {data.mediaURL &&
                            <div>
                            <AttachFileIcon title="Open File" onClick={()=>window.open(data.mediaURL)} />
                                {data.caption}
                            </div>
                        }
                    </div>
                </div>
            </div>

            <Handle id="b" type="target" position={Position.Left}/>
            <HandleDot
                // addVisible={isAddVisible(rest)}
                addVisible={true}
                id="b"
                type="source"
                position={Position.Right}
                onAdd={(v) => onAdd(v, rest)}
            />
        </div>
    );
};

