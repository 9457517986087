import React, {useState} from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {TextField, Tooltip} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import EditIcon from "@mui/icons-material/Edit";
import {Handle, Position} from "reactflow";
import {HandleDot} from "../handle";

export const DelayNodeEditModal = ({open, handleClose, data}) => {

    const isEdit = !!data?.id

    const getInitialData = () => {
        if (!isEdit) {
            return {min: 0, hour: 0, days: 0}
        } else {
            const {min = 0, hour = 0, days = 0} = data?.data || {}
            return {
                min: Number(min), hour: Number(hour), days: Number(days)
            }
        }
    }

    const [state, setState] = useState(getInitialData())
    const {min, hour, days} = state;

    const onAdd = () => {
        handleClose({min, hour, days}, isEdit);
    };
    const onClose = () => {
        handleClose({min: -1, hour: -1, days: -1});
    };
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={style}>
                <h1 className="font-bold text-sky-600 text-2xl">Delay</h1>
                <p className="text-slate-500 text-sm">You can add a delay for the next operation to be performed.</p>
                <div className="flex justify-evenly mt-8 mb-8">
                    <div className="mr-2">
                        <TextField
                            focused
                            value={min}
                            error={min > 59 ? true : false}
                            helperText={min > 59 ? "Not Valid" : ""}
                            inputProps={{
                                type: "number",
                                max: 60,
                            }}
                            onChange={(e) => setState({...state, min: e.target.value})}
                            className="mr-2 "
                            label="Minute"
                            color="primary"
                        />
                    </div>
                    <div className="mr-2">
                        <TextField
                            focused
                            value={hour}
                            error={hour > 23 ? true : false}
                            helperText={hour > 23 ? "Not Valid" : ""}
                            inputProps={{
                                type: "number",
                                max: 23,
                            }}
                            onChange={(e) => setState({...state, hour: e.target.value})}
                            className="mr-2 "
                            label="Hour"
                            color="primary"
                        />
                    </div>
                    <TextField
                        inputProps={{
                            type: "number",
                        }}
                        value={days}
                        onChange={(e) => setState({...state, days: e.target.value})}
                        className="mr-2 "
                        label="Days"
                        color="primary"
                        focused
                    />
                </div>
                {/* <button className="w-full mt-6 bg-sky-600 text-white btn">
					Submit
				</button> */}
                <Button
                    variant="contained"
                    onClick={onAdd}
                >
                    {isEdit ? 'Update' : 'Add'}
                </Button>
                <Button
                    style={{marginLeft: 12}}
                    variant="outlined"
                    onClick={onClose}
                >
                    Close
                </Button>
            </Box>
        </Modal>
    );
};

export const DelayNode = (props) => {
    const {data, selected, onEdit, onAdd, isAddVisible, ...rest} = props;

    return (
        <div
            className={`w-40  shadow-md rounded-md bg-white   ${
                selected
                    ? "border-solid border-2 border-indigo-500/100"
                    : ""
            } `}
        >
            <div className="flex flex-col">
                <div
                    className="max-h-max px-2 py-1 text-left text-black text-xs font-bold rounded-t-md bg-teal-300">
                    Delay <Tooltip title={rest.id}>
                    <ErrorOutlineIcon style={{fontSize: 10}}/>
                </Tooltip>

                    <Tooltip className='ml-2' title='Edit'>
                        <EditIcon style={{fontSize: 12}}
                                  onClick={() => onEdit(props)}/>
                    </Tooltip>
                </div>
                <div className="px-3 py-2 ">
                    <div className="text-xs font-normal text-black">
                        Delay: {data.hour} hour {data.min} min {data.sec}{" "}
                        sec
                    </div>
                </div>
            </div>

            <Handle id="b" type="target" position={Position.Left}/>
            <HandleDot
                // addVisible={isAddVisible(rest)}
                addVisible={true}
                id="b"
                type="source"
                position={Position.Right}
                onAdd={(v) => onAdd(v, rest)}
            />
        </div>
    );
};

