import ControlPointIcon from "@mui/icons-material/ControlPoint";
import {Handle} from "reactflow";
import {Tooltip} from "@mui/material";

export const HandleDot = ({
                              position, type, id, onAdd, style, addVisible
                          }) => {

    return (
        <div>
            {addVisible && <div style={{...style, top: (style?.top || 10) - 10}}
            >
                <Tooltip title={"Add new node1"}>
                    <ControlPointIcon
                        onClick={e => {
                            console.log('asdasdasdasdasdd')
                            onAdd(e)
                        }}
                        style={{
                            position: 'absolute',
                            top: 10,
                            right: 2,
                            transform: 'translateY(-50%)',
                            fontSize: 12,
                        }}
                    />
                </Tooltip>
            </div>}
            <Handle
                id={id}
                type={type}
                position={position}
                style={style}
            />
        </div>
    );
}
