import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css'
import {CommunicateContextProvider} from './store/CommunicateContext';
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import ErrorBoundary from './components/Errorbounday'

ReactDOM.render(
    <CommunicateContextProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ErrorBoundary>
                <App/>
            </ErrorBoundary>
        </LocalizationProvider>
    </CommunicateContextProvider>,

    document.getElementById("root")
);

