import React, {useEffect, useState} from "react";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import Cookies from "js-cookie";
import SelectTemplateModal from "../../WorkFlow/SelectTemplateModal";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import EditIcon from "@mui/icons-material/Edit";
import {Handle, Position} from "reactflow";
import {HandleDot} from "../handle";

export const SendMessageEditModal = ({open, handleClose, data, appAccountId}) => {

    const isEdit = !!data?.id;

    useEffect(() => {
        if (isEdit) {
            const {templateId, selectedLanguage, accountId, selectedMediaUrl} = data?.data || {}

            setSelectedTemplate(templateId)
            setSelectedLanguage(selectedLanguage)
            setSelectedMedia(selectedMediaUrl)
            setAccountId(accountId)
        }
    }, [isEdit]);


    const [contactList, setContactList] = useState([]);
    const [accountId, setAccountId] = useState(1);
    const [templates, setTemplates] = useState([]);
    const [templateName, setTemplateName] = useState(undefined);
    const [templateLoading, setTemplateLoading] = useState(false);
    const [variables, setVariables] = useState([]);
    const [selectedMedia, setSelectedMedia] = useState({});
    const [selectedLanguage, setSelectedLanguage] = useState("");
    const [offset, setOffset] = useState(0);
    const [contactFormData, setContactFormData] = useState();
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    const authToken = `Token ${Cookies.get("auth_token")}`;

    const limit = 50;
    const authHeaders = {
        headers: {Authorization: `Token ${Cookies.get("auth_token")}`},
    };


    const getContactList = async () => {
        const api2 = `https://preprod-api.tbplabs.tech/contacts/contactList?OFFSET=${offset}&LIMIT=${limit}`;

        try {
            const resp2 = await axios.post(api2, contactList, authHeaders);
            setContactList((prevList) => [
                ...prevList,
                ...resp2.data.data.content,
            ]);
        } catch (error) {
            console.error("Error fetching contact list:", error);
        }
    };

    useEffect(() => {
        getContactList();
    }, [offset]);

    var requestOptions = {
        method: "GET",
        headers: {Authorization: authToken},
        redirect: "follow",
    };

    useEffect(() => {
        fetch(
            "https://preprod-api.tbplabs.tech/contacts/Contactform",
            requestOptions
        )
            .then((res) => res.json())
            .then((data) => {
                setContactFormData(data.data);
            });
    }, []);

    useEffect(() => {
        const getTemplates = async () => {
            if (appAccountId !== undefined) {
                setTemplateLoading(true);
                try {
                    const response = await fetch(
                        `https://preprod-api.tbplabs.tech/whatsapp/templates?appAccountId=${appAccountId}`,
                        {
                            headers: {
                                Authorization: `Token ${Cookies.get(
                                    "auth_token"
                                )}`,
                            },
                        }
                    );
                    const responseData = await response.json();
                    setTemplates(responseData.data);
                } catch (error) {
                    console.error("Error fetching templates:", error);
                } finally {
                    setTemplateLoading(false);
                }
            }
        };

        getTemplates();
    }, [appAccountId]);


    const onAdd = (isNotEmpty) => {
        if (!isNotEmpty) {
            handleClose("-999");
        } else {
            if (selectedTemplate?.id) {
                handleClose({
                        templateId: selectedTemplate.id,
                        selectedLanguage,
                        accountId,
                        appAccountId,
                        selectedMedia
                    }
                    , isEdit
                );

            } else {
                alert('Please select template first')
            }
        }
    };

    return (
        <SelectTemplateModal
            openModal={open}
            closeModal={(v) => onAdd(v)}
            templates={templates}
            accountid={accountId}
            appAccountId={appAccountId}
            setTemplateName={setTemplateName}
            templateName={templateName}
            variables={variables}
            setVariables={setVariables}
            selectedMedia={selectedMedia}
            setSelectedMedia={setSelectedMedia}
            contactFormData={contactFormData}
            setSelectedTemplate={setSelectedTemplate}
            selectedTemplate={selectedTemplate}
            isEdit={isEdit}
        />

    );
};

export const SendMessageNode = (props) => {
    const {data, selected, isAddVisible, onAdd, onEdit, ...rest} = props;
    return (
        <div
            className={`w-40  shadow-md rounded-md bg-white   ${
                selected
                    ? "border-solid border-2 border-indigo-500/100"
                    : ""
            } `}
        >
            <div className="flex flex-col">
                <div
                    className="max-h-max px-2 py-1 text-left text-black text-xs font-bold rounded-t-md bg-teal-300">
                    Send Message
                    <Tooltip title={rest.id}>
                        <ErrorOutlineIcon style={{fontSize: 10}}/>
                    </Tooltip>
                    <Tooltip className='ml-2' title='Edit'>
                        <EditIcon style={{fontSize: 12}}
                                  onClick={() => onEdit(props)}/>
                    </Tooltip>
                </div>
                <div className="px-3 py-2 ">
                    <div className="text-xs font-normal text-black">
                        Template : <div style={{fontSize: 9, overflowWrap: 'break-word'}}>
                        {props.templateMap[data?.templateId] || data?.templateId}
                    </div>
                    </div>
                </div>
            </div>

            <Handle id="b" type="target" position={Position.Left}/>
            <HandleDot
                // addVisible={isAddVisible(rest)}
                addVisible={true}
                id="b"
                type="source"
                position={Position.Right}
                onAdd={(v) => onAdd(v, rest)}
            />
        </div>
    );
};
