import axiosInstance from './axiosInstance';

// GET request
export const get = async (url, params = {}, config = {}) => {
    try {
        const response = await axiosInstance.get(url, { params, ...config });
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : error;
    }
};

export const getData = async (url, params = {}, config = {}) => {
    try {
        const response = await axiosInstance.get(url, { params, ...config });
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : error;
    }
};

// POST request
export const postData = async (url, data, config = {}) => {
    try {
        const response = await axiosInstance.post(url, data, { ...config });
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : error;
    }
};

// PUT request
export const put = async (url, data, config = {}) => {
    try {
        const response = await axiosInstance.put(url, data, { ...config });
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : error;
    }
};

// PATCH request
export const patchData = async (url, data, config = {}) => {
    try {
        const response = await axiosInstance.patch(url, data, { ...config });
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : error;
    }
};

// DELETE request
export const del = async (url, config = {}) => {
    try {
        const response = await axiosInstance.delete(url, { ...config });
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : error;
    }
};


export const postFile = async (url, file, config = {}) => {
    try {
        const formData = new FormData();
        formData.append('file', file);

        const response = await axiosInstance.post(url, formData, config);
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : error;
    }
};
