import React, { useEffect, useState, lazy, Suspense } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import AuthApi from "./components/AuthApi";
import Cookies from "js-cookie";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import CenterLoader from "./utils/CenterLoader";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import WorkflowV2 from "./components/WorkflowV2";

const Home = lazy(() => import("./components/Home"));
const Login = lazy(() => import("./components/Login"));
const ProtectedLogin = lazy(() => import("./components/ProtectedLogin"));
const ProtectedRoute = lazy(() => import("./components/ProtectedRoute"));

const Contact = lazy(() => import("./components/Home-page-components/Contact"));
const Campaigns = lazy(() =>
	import("./components/Home-page-components/Campaigns/Campaigns")
);
const Candidates = lazy(() =>
	import("./components/Home-page-components/Candidates")
);
const Communicate = lazy(() =>
	import("./components/Home-page-components/Communicate/Communicate")
);
const Analytics = lazy(() =>
	import("./components/Home-page-components/Analytics")
);
const AddForm = lazy(() => import("./components/Home-page-components/AddForm"));
const Listing = lazy(() => import("./components/Home-page-components/Listing"));
const Settings = lazy(() =>
	import("./components/Home-page-components/Settings")
);
const Dashboard = lazy(() =>
	import("./components/Home-page-components/Dashboard")
);
const AddCandidate = lazy(() =>
	import("./components/Home-page-components/AddCandidate")
);
const CandidateByID = lazy(() =>
	import("./components/Home-page-components/CandidateByID")
);
const Test = lazy(() => import("./components/Home-page-components/Test"));
const AddCampaign = lazy(() =>
	import("./components/Home-page-components/Campaigns/AddCampaign")
);
const Sidebar = lazy(() => import("./components/utils/components/Sidebar"));
const WorkFlow = lazy(() => import("./components/WorkFlow/WorkFlow"));
const App1 = lazy(() => import("./components/WorkFlow/App"));
const ForgotPassword = lazy(() => import("./components/ForgotPassword"));
const ResetPassword = lazy(() => import("./components/ResetPassword"));

function App() {
	const [auth, setAuth] = useState(false);
	const [toggleSlider, setToggleSlider] = useState(true);

	function readCookie() {
		const currentUser = Cookies.get("auth_token");
		if (currentUser) {
			setAuth(true);
		} else return;
	}

	useEffect(() => {
		readCookie();
	}, []);

	useEffect(() => {
		const interceptor = axios.interceptors.response.use(
			(response) => response,
			(error) => {
				if (error.response && error.response.status === 401) {
					setAuth(false);
				}
				return Promise.reject(error);
			}
		);

		return () => {
			axios.interceptors.response.eject(interceptor);
		};
	}, []);

	useEffect(() => {
		if (!auth && window.location.pathname === "/") {
			window.location.href = "/login";
		}
	}, [auth]);

	return (
		<AuthApi.Provider
			value={{ auth, setAuth, toggleSlider, setToggleSlider }}
		>
			<div className="App">
				<BrowserRouter>
					<Suspense fallback={<CenterLoader />}>
						<Switch>
							<ProtectedLogin
								path="/Login"
								auth={auth}
								component={Login}
							/>
							<Route
								path="/forgotpassword"
								component={ForgotPassword}
							/>
							<Route
								path="/resetPassword"
								component={ResetPassword}
							/>
						</Switch>
						{auth && <Sidebar />}
						<Switch>
							<ProtectedRoute
								path="/workflow"
								auth={auth}
								setAuth={setAuth}
								component={() => (
									<Suspense fallback={<CenterLoader />}>
										<WorkFlow />
									</Suspense>
								)}
							/>
							<ProtectedRoute
								path="/workflow-editor/:id"
								auth={auth}
								setAuth={setAuth}
								component={() => (
									<Suspense fallback={<CenterLoader />}>
										<WorkflowV2 />
									</Suspense>
								)}
							/>

							<ProtectedRoute
								path="/workflow-editor"
								auth={auth}
								setAuth={setAuth}
								component={() => (
									<Suspense fallback={<CenterLoader />}>
										<WorkflowV2 />
									</Suspense>
								)}
							/>

							<ProtectedRoute
								path="/Home"
								auth={auth}
								setAuth={setAuth}
								component={() => (
									<Suspense fallback={<CenterLoader />}>
										<Home />
									</Suspense>
								)}
							/>
							<ProtectedRoute
								path="/wf"
								auth={auth}
								setAuth={setAuth}
								component={() => (
									<Suspense fallback={<CenterLoader />}>
										<App1 />
									</Suspense>
								)}
							/>
							<ProtectedRoute
								path="/Dashboard"
								auth={auth}
								setAuth={setAuth}
								component={() => (
									<Suspense fallback={<CenterLoader />}>
										<Dashboard />
									</Suspense>
								)}
							/>

							<ProtectedRoute
								path="/Contact"
								auth={auth}
								setAuth={setAuth}
								component={() => (
									<Suspense fallback={<CenterLoader />}>
										<Contact />
									</Suspense>
								)}
							/>
							<ProtectedRoute
								path="/Campaigns"
								auth={auth}
								setAuth={setAuth}
								component={() => (
									<Suspense fallback={<CenterLoader />}>
										<Campaigns />
									</Suspense>
								)}
							/>
							<ProtectedRoute
								path="/addcampaign"
								auth={auth}
								setAuth={setAuth}
								component={() => (
									<Suspense fallback={<CenterLoader />}>
										<AddCampaign />
									</Suspense>
								)}
							/>
							<ProtectedRoute
								path="/Candidates"
								auth={auth}
								setAuth={setAuth}
								component={() => (
									<Suspense fallback={<CenterLoader />}>
										<Candidates />
									</Suspense>
								)}
							/>
							<ProtectedRoute
								path="/Communicate"
								auth={auth}
								setAuth={setAuth}
								component={() => (
									<Suspense fallback={<CenterLoader />}>
										<Communicate />
									</Suspense>
								)}
							/>
							<ProtectedRoute
								path="/Analytics"
								auth={auth}
								setAuth={setAuth}
								component={() => (
									<Suspense fallback={<CenterLoader />}>
										<Analytics />
									</Suspense>
								)}
							/>
							<ProtectedRoute
								path="/AddForm"
								auth={auth}
								setAuth={setAuth}
								component={() => (
									<Suspense fallback={<CenterLoader />}>
										<AddForm />
									</Suspense>
								)}
							/>
							<ProtectedRoute
								path="/Listing/:id"
								auth={auth}
								setAuth={setAuth}
								component={() => (
									<Suspense fallback={<CenterLoader />}>
										<Listing />
									</Suspense>
								)}
							/>
							<ProtectedRoute
								path="/Settings"
								auth={auth}
								setAuth={setAuth}
								component={() => (
									<Suspense fallback={<CenterLoader />}>
										<Settings />
									</Suspense>
								)}
							/>
							<ProtectedRoute
								path="/AddCandidate"
								auth={auth}
								setAuth={setAuth}
								component={() => (
									<Suspense fallback={<CenterLoader />}>
										<AddCandidate />
									</Suspense>
								)}
							/>
							<ProtectedRoute
								path="/Foo"
								auth={auth}
								setAuth={setAuth}
								component={() => (
									<Suspense fallback={<CenterLoader />}>
										<CandidateByID />
									</Suspense>
								)}
							/>
						</Switch>
					</Suspense>
				</BrowserRouter>
			</div>
		</AuthApi.Provider>
	);
}

export default App;
