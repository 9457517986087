import React, {useState} from "react";
import Tooltip from "@mui/material/Tooltip";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import EditIcon from "@mui/icons-material/Edit";
import {Handle, Position} from "reactflow";
import {HandleDot} from "../handle";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Divider from '@mui/material/Divider';
import {NodeTypes} from "../constants";
import {TextareaAutosize} from "@mui/material";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const inputStyle = {
    marginRight: 12,
    width: 250,
}

export const QuickReplyEditModal = ({open, handleClose, data: listData}) => {
    const {data} = listData || {}

    const isEdit = !!listData?.id;

    const [list, setList] = useState(data?.list || [
        {name: '', description: ''},
        {name: '', description: ''},
        {name: '', description: ''},
    ]);

    const [description, setDescription] = useState(data?.description || "");
    const [buttonName, setButtonName] = useState(data?.action?.name || "");

    console.log('listData', list)

    const onAdd = () => {
        if (list.length && description && buttonName) {
            handleClose(NodeTypes.QuickReply, {
                description,
                action: {
                    type: "button",
                    name: buttonName
                },
                list
            }, isEdit);
        } else {
            alert('Please fill all the details')
        }
    };
    const onClose = () => {
        handleClose(NodeTypes.QuickReply, null, isEdit);
    };


    return (

        <Modal open={open} onClose={onClose}>
            <Box sx={style}>
                <h1 className="font-bold text-sky-600 text-2xl mb-4">Quick List</h1>
                <TextareaAutosize
                    minRows={3}
                    className="p-2"
                    value={description}
                    style={{...inputStyle, border: '1px solid #cecece', borderRadius: 4, width: 300}}

                    placeholder="Write message here..."
                    onChange={(e) => setDescription(e.target.value)}
                />
                <TextField
                    label={"Action Button name"}
                    variant="outlined"
                    style={inputStyle}
                    placeholder="Action Button name"
                    value={buttonName}
                    onChange={(e) => setButtonName(e.target.value)}
                />
                <Divider style={{margin: 24}}/>
                <div className="mt-3">
                    <div>Total List Items: {list.length}</div>
                    {
                        !list?.length &&
                        <Button variant="contained"
                                onClick={() => {
                                    setList([{name: '', description: ''}])
                                }}>
                            Create list items</Button>
                    }
                    {(list || []).map((item, index) => (
                        <div key={index} className='flex m-2 items-center '>
                            <div className='mr-3'>{index + 1}.</div>
                            <TextField label={"Name"}
                                       variant="outlined"
                                       placeholer={"Name"}
                                       style={inputStyle}
                                       value={item.name} onChange={(e) => {
                                const newList = [...list];
                                newList[index].name = e.target.value;
                                setList(newList);
                            }}
                            />
                            <TextField
                                width={400}
                                variant="outlined"
                                label={"Description"}
                                style={inputStyle}
                                placeholer={"Description"}
                                value={item.description}
                                onChange={(e) => {
                                    const newList = [...list];
                                    newList[index].description = e.target.value;
                                    setList(newList);
                                }}/>
                        </div>
                    ))}
                </div>
                <Divider style={{margin: 24}}/>
                <div className='mt-4'>
                    <Button
                        variant="contained"
                        onClick={onAdd}
                    >
                        {isEdit ? 'Update' : 'Add'}
                    </Button>
                    <Button
                        style={{marginLeft: 12}}
                        variant="outlined"
                        onClick={onClose}
                    >
                        Close
                    </Button>
                </div>
            </Box>
        </Modal>
    );
};

export const QuickReplyNode = (props) => {
    const {data, selected, isAddVisible, onAdd, onEdit, ...rest} = props;
    return (
        <div
            className={`shadow-md rounded-md bg-white   ${
                selected
                    ? "border-solid border-2 border-indigo-500/100"
                    : ""
            } `}
        >
            <div className="flex flex-col">
                <div
                    className="max-h-max px-2 py-1 text-left text-black text-xs font-bold rounded-t-md bg-teal-300">
                    Quick List
                    <Tooltip title={rest.id}>
                        <ErrorOutlineIcon style={{fontSize: 10}}/>
                    </Tooltip>
                    <Tooltip className='ml-2' title='Edit'>
                        <EditIcon style={{fontSize: 12}}
                                  onClick={() => onEdit(props)}/>
                    </Tooltip>
                </div>
                <div className="px-3 py-2">
                    <div className="text-xs">
                        Description: <b>{data?.description}</b>
                    </div>
                    <div className="text-xs py-2">
                        Action Name: <b>{data?.action?.name}</b>
                    </div>
                    <Divider style={{margin: 6}}/>
                    <div>Total List items: {data?.list?.length}</div>
                    <div className="py-2 text-xs">
                        {data?.list?.map((item, index) => {
                            return (
                                <div key={index} className='m-2 flex'>
                                    <div className={'mr-2'}>{index + 1} .</div>
                                    <div className={'mr-2'}>Name: <b>{item.name}</b></div>
                                    <div>Description: <b>{item.description}</b></div>
                                </div>
                            )
                        })
                        }
                    </div>
                </div>

                <Handle id="b" type="target" position={Position.Left}/>
                <HandleDot
                    // addVisible={isAddVisible(rest)}
                    addVisible={true}
                    id="b"
                    type="source"
                    position={Position.Right}
                    onAdd={(v) => onAdd(v, rest)}
                />
            </div>
        </div>
    );
};
