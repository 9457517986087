import axios from 'axios';
import Cookies from "js-cookie";

// Create an Axios instance
const axiosInstance = axios.create({
    baseURL: 'https://preprod-api.tbplabs.tech/', // Replace with your API base URL
    headers: {
        'Content-Type': 'application/json',
    },
    timeout: 10000, // Optional: set a timeout
});

// Add interceptors if needed (e.g., for adding auth tokens)
axiosInstance.interceptors.request.use(
    (config) => {
        const token = Cookies.get("auth_token");
        if (token) {
            config.headers.Authorization = `Token ${token}`;
        }
        // You can modify the config before sending the request
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;
