import Cookies from "js-cookie";
import {postFile} from "../apis/api";
import {APIs} from "../apis/urls";

export const uploadFileToS3 = async (file, setIsLoading) => {
    setIsLoading(true);
    const token = Cookies.get("auth_token");
    const config = {
        headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'multipart/form-data',
        }
    }
    return postFile(APIs.upload, file, config).then(response => {
        setIsLoading(false);
        return response.data?.fileUrl
    }).catch(error => {
        setIsLoading(false);
        console.error(error);
        alert("Something went wrong. Please try again.");
        return null
    })
}


// Function to parse and format the component stack
export const formatComponentStack = (componentStack) => {
    // Split the stack trace into lines
    let lines = componentStack.split('\n').map(line => line.trim()).filter(line => line);
    if(lines.length && lines[0].split){
        const [componentName] = lines[0].split(' at ');
        return {
            componentName: componentName.trim(),
        };
    }
    return {}
};
